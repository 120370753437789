@import "styles/abstracts/index";
* {
  border: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  --primary: #{$primary};

  --yellow-dark: #ffcc3c;
  --yellow: #{$yellow};
  --yellow-medium: #ffeec6;
  --yellow-light: #fff8f1;
  --yellow-dull: #f9f2e8;
  --yellow-normal: #face63;

  --orange-light: #fffde8;
  --orange: #{$orange};

  --green: #{$green};
  --green-lime: #48d756;
  --light-green: #c9fad6;
  --green-pale: #70dcbb;
  --green-dull: #0ac195;
  --green-bright: #00b027;
  --green-dull-light: #daf4de;

  --red: #{$red};
  --red-pale: #dc2222;
  --red-dull-light: #ffdfdf;

  --pink: #ff7272;

  --black: #000b33;
  --black-dark: #000000;
  --black-light: #7a7a7a;
  --black-medium: #1a202c;

  --grey: #d7d7d7;
  --grey-light: #f4f4f4;
  --grey-superlight: #f9f9f9;
  --grey-normal: #a5afbf;
  --grey-normal-light: #d9e0eb;
  --grey-normal-bg: #dfe5ed;
  --grey-dark: #767676;
  --grey-medium: #ededed;
  --white: white;

  --blue-hyperlink: #5c6dff;
  --blue: #377cfd;
  --blue-light: #d9f1ff;
  --blue-dark: #000b6b;
  --blue-highlight: #a3ffff;
  --blue-light-bg: #e4f9ff;
  --blue-dull: #dbe8ef;
  --blue-alpha: #eff3f9;
  --blue-lighten: #e0e7f3;

  --purple-light: #e3e8ff;
  --purple: #8e80e5;
  --purple2: #a9b2ff;
  --purple-dark: #0008c1;

  --backdrop: rgba(0, 54, 135, 0.81);
  --shadow: 0px 0px 72px rgba(0, 0, 0, 0.07);
  --shadow2: 0px 4px 40px rgba(43, 89, 255, 0.08);
  --shadow3: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  --gradient: linear-gradient(
    92.28deg,
    #0f1b3f 2.73%,
    #1854ef 54.29%,
    #345d83 99.16%
  );

  --dashboard-bg: #fff7e2;

  --table-header-bg: #dae3f0;
  --table-header-color: #3d3d3d;

  --hero-bg: #eff3f9;
  --hero-bg-2: #345d83;

  --font-primary: var(--roboto-font), sans-serif;
  --font-secondary: var(--inconsolata-font), monospace;
  --font-poppins: var(--poppins-font), sans-serif;
  --font-rubik: var(--rubik-font), sans-serif;
  --border-radius-xs: 2px;
  --border-radius-sm: 5px;
  --border-radius-md: 10px;
  --border-radius-lg: 15px;
  --input-placeholder-color: rgba(126, 133, 142, 0.6);

  background-color: var(--white);
  overflow-x: hidden;
  font-family: var(--font-primary);
  min-width: 360px;
}

.error {
  color: var(--red);
}

::selection {
  color: black;
  background: var(--yellow);
}

@include md-down() {
  html {
    font-size: 14px;
  }
}
